<template>
  <div>
    <div>
      <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <form class="mt-4" novalidate @submit.prevent="handleSubmit(onSubmit)">
          <div class="form-group">
                <label for="referral">추천인</label>
                <input type="text" v-model="user.referral"
                  :class="'form-control mb-0'" id="referral"
                  aria-describedby="emailHelp" placeholder="추천인 이메일" :readonly="checkReferral">
                  <div v-if="!checkReferral" class="invalid-feedback-success">
                    <button type="button" class="btn btn-primary float-right" @click="doCheckReferral()">추천인 확인</button>
                  </div>
              </div>
          <ValidationProvider vid="name" rules="required|email" v-slot="{ errors }">
            <div class="form-group">
              <label for="email">이매일 *</label>
              <input type="email" v-model="user.account"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="email"
                aria-describedby="emailHelp" placeholder="Email" :readonly="checkEmail">
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
              <div v-if="errors.length === 0 && !checkEmail" class="invalid-feedback-success">
                <button type="button" class="btn btn-primary float-right" @click="getCode(errors)">이메일 인증</button>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider v-if="checkEmail && !checkCode" vid="name" rules="required" v-slot="{ errors }">
            <div class="form-group">
              <label for="code">인증 코드 *</label>
              <input type="code" v-model="user.code"
              :style="{textTransform: 'uppercase'}"
                :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="code"
                aria-describedby="codeHelp" placeholder="인증코드">
              <div class="invalid-feedback">
                <span>{{ errors[0] }}</span>
              </div>
              <div v-if="checkEmail && !checkCode" class="invalid-feedback-success">
                <button type="button" class="btn btn-primary float-right" @click="checkCodeAction">인증 코드 확인</button>
              </div>
            </div>
          </ValidationProvider>

          <div v-if="checkEmail && checkCode">
            <ValidationProvider vid="password" rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="password">비밀번호 *</label>
                <input type="password" v-model="user.pw"
                  :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="password" placeholder="비밀번호">
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>
            <ValidationProvider vid="name" rules="required" v-slot="{ errors }">
              <div class="form-group">
                <label for="name">닉네임 *</label>
                <input type="text" v-model="user.nickname"
                  :class="'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')" id="name"
                  aria-describedby="emailHelp" placeholder="닉네임">
                <div class="invalid-feedback">
                  <span>{{ errors[0] }}</span>
                </div>
              </div>
            </ValidationProvider>

            <div class="d-inline-block w-100">
              <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                <input type="checkbox" class="custom-control-input" :id="formType" v-model="agree">
                <label class="custom-control-label" :for="formType">I accept <a href="#">Terms and Conditions</a></label>
              </div>
              <button type="submit" class="btn btn-primary float-right">회원가입</button>
            </div>
          </div>
          <div class="sign-info">
            <span class="dark-color d-inline-block line-height-2">
              <router-link to="/auth/sign-in" class="iq-waves-effect pr-4">
                이미 계정이 있으신가요?
              </router-link>
            </span>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>

import auth from '../../../services/auth'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SignUpForm',
  props: ['formType'],
  computed: {
    ...mapGetters({
      users: 'Setting/usersState'
    })
  },
  mounted () {
    this.checkRef()
  },
  data: () => ({
    agree: false,
    user: {
      account: '',
      code: '',
      pw: '',
      nickname: '',
      referral: ''
    },
    checkReferral: false,
    checkEmail: false,
    checkCode: false,
    token: ''
  }),
  methods: {
    ...mapActions({
    }),
    checkRef () {
      const ref = this.$route.query?.referral
      if (ref === undefined) return
      this.checkReferral = true
      this.user.referral = ref
    },
    onSubmit () {
      this.jwtRegister()
    },
    async doCheckReferral () {
      if (this.user.referral === '') {
        alert('추천인 이메일을 입력해주세요.')
        return
      }
      const res = await auth.findRef(this.user.referral)
      if (!res.data.resultCode) {
        alert('존재하지 않은 이메일입니다.\n정확한 이메일을 입력해주세요.')
        return
      }
      alert('추천인 인증되었습니다.')
      this.checkReferral = true
    },
    async getCode (errors) {
      if (this.user.account === '') {
        alert('이메일을 입력해주세요.')
        return
      }
      if (errors.length > 0) {
        alert('이메일을 확인해주세요.')
        return
      }
      const raw = await auth.getCode(this.user.account)
      const res = raw.data

      if (!res.resultCode) {
        alert('이미 가입된 이메일 입니다.')
        return
      }

      alert('입력한 이메일로 받은 인증코드를 입력해주세요.')
      this.checkEmail = true
    },
    async checkCodeAction () {
      if (this.user.code === '') {
        alert('인증코드를 입력해주세요.')
        return
      }
      const raw = await auth.checkCode(this.user.account, this.user.code)
      const res = raw.data
      if (!res.resultCode) {
        alert('정확한 인증코드를 입력해주세요.')
        return
      }
      alert('이메일 인증이 완료되었습니다.')
      this.token = res.token
      this.checkCode = true
    },
    jwtRegister () {
      // if (!this.agree) {
      //   alert('정책에 동의해주세요.')
      //   return;
      // }
      if (!(this.checkCode && this.checkEmail)) {
        return
      }

      if (!this.checkReferral) {
        this.user.referral = ''
      }

      auth.register_v2(this.user, this.token).then(res => {
        if (res.data.resultCode) {
          alert('회원가입이 완료되었습니다.')
          this.$router.push('/auth/sign-in')
        } else {
          if (res.data.message === 'not exist user') {
            alert('추천인 링크가 올바르지 않습니다.')
            this.checkReferral = false
            this.user.referral = ''
            return
          }
          alert('관리자에게 문의해주세요.')
        }
      })
    }
  }
}

</script>
<style>
.sum__margin {
  margin: 3rem 0;
}
</style>
