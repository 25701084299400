<template>
  <div :style="{display: 'flex', justifyContent: 'center', width: '100%'}">
    <div :style="{ padding: '30px 0', width:'50vw'}">
      <h1 class="mb-0">회원가입</h1>
    <!-- <p>Enter your email address and password to access admin panel.</p> -->
    <!-- <tab-nav :pills="true" class="nav-fill mb-3" id="pills-tab-1">
      <tab-nav-items :active="true" id="pills-jwt-tab-fill"
                     href="#pills-jwt-fill" ariaControls="pills-jwt-fill"
                     role="tab" :ariaSelected="true" title="JWT" />
      <tab-nav-items :active="false" id="pills-firebase-tab-fill"
                     href="#pills-firebase-fill" ariaControls="pills-firebase-fill"
                     role="tab" :ariaSelected="false" title="Firebase" />
      <tab-nav-items :active="false" id="pills-oauth0-tab-fill"
                     href="#pills-oauth0-fill" ariaControls="pills-oauth0-fill"
                     role="tab" :ariaSelected="false" title="OAuth0" />
    </tab-nav> -->
        <sign-up-form formType="jwt"></sign-up-form>
  </div>
  </div>
</template>
<script>

import SignUpForm from './Forms/SignUpForm'
import { core } from '../../config/pluginInit'

export default {
  name: 'SignUp',
  components: { SignUpForm },
  mounted () {
    core.index()
    const loggedIn = localStorage.getItem('hypercert_access_token')
    if (loggedIn !== undefined && loggedIn !== null) {
      this.$router.push({ name: 'dashboard.latest' })
    }
  },
  methods: {
    // loginOAuth0 () {
    //   new auth0.WebAuth(constant.auth0Config).authorize()
    // }
  }
}
</script>
